import React, { useState } from 'react';
import { Col, Row, Button, Card, CardBody, Collapse, Table, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ConfirmationPremiumPlan = ({ premiumResp, premiumValue }) => {
    const { t } = useTranslation();
    const t2 = (label) => { return t('en_translations:'+label) }
    const [panelOpen, setPanelOpen] = useState({
        basicBenefit: true,
        addBenefit: true
    });
    const toggleBasicBenefit = () => setPanelOpen({...panelOpen, basicBenefit: !panelOpen.basicBenefit});
    const toggleAddBenefit = () => setPanelOpen({...panelOpen, addBenefit: !panelOpen.addBenefit});
    const planSelected = premiumValue.planSelected;
    if (premiumValue) return (
        <div className="mt-3">
        <Row><Col><h5>{t('premiumPlan')}</h5></Col></Row>
        
        <Button className={"accordion-toggle mt-3 " + (panelOpen.basicBenefit ? "collapse-icon" : "expand-icon")} 
            onClick={e => toggleBasicBenefit(e)}>{t('basicbenefits')}<br></br><i>{t2("basicbenefits")}</i>
        </Button>
        <Collapse isOpen={panelOpen.basicBenefit}>
            <Card><CardBody>
                {premiumResp.basicBenefits ? <Table bordered>
                    <thead><tr><th>{t('code')}</th><th>{t('description')}</th></tr></thead>
                    <tbody>
                    {premiumResp.basicBenefits.map((basicBenefit,i) => {
                        return <tr key={i}>
                            <td>{basicBenefit.code}</td>
                            <td>{basicBenefit.desc}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>: "" }
                </CardBody></Card>
        </Collapse>
                    {console.log('Check value:',planSelected)}
        {premiumValue.additionalBenefitsChecked ? 
            premiumValue.additionalBenefitsChecked.find(addBenefit => addBenefit.checked === true && planSelected.premium.additionalBenefitPremium > 0)? (
        <div>
        <Button className={"accordion-toggle mt-3 " + (panelOpen.addBenefit ? "collapse-icon" : "expand-icon")} 
            onClick={e => toggleAddBenefit(e)}>{t('additionalbenefits')}<br></br><i>{t2("additionalbenefits")}</i>
        </Button>
        <Collapse isOpen={panelOpen.addBenefit}>
            <Card><CardBody>
                {premiumValue.additionalBenefitsChecked ? 
                    premiumValue.additionalBenefitsChecked.find(addBenefit => addBenefit.checked === true)?
                <Table bordered>
                        <thead><tr><th>{t('code')}</th><th>{t('description')}</th></tr></thead>
                        <tbody>
                        {premiumValue.additionalBenefitsChecked.map((additionalBenefit,i) => {
                            return additionalBenefit.checked === true?
                             <tr key={i}>
                                <td>{additionalBenefit.code}</td>
                                <td>{additionalBenefit.desc}</td>
                            </tr>: null
                        })}
                        </tbody>
                </Table>: "" :""}
            </CardBody></Card>
        </Collapse>
        </div>): null : null}
        </div>
    )
    else return "";
};

export default ConfirmationPremiumPlan;