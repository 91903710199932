import * as constant from './Constant';

export const URL_BASED = "https://greatAXGateway.greateasterngeneral.com/ax/v1/portal";
export const URL_SIDEMENU = "/me1/menuItem/getMenu";
// export const URL_HOME_DASHBOARD = "/rws/dashboard/init";
export const URL_LOOKUP_USERTYPE = "/lu05/lookup/USER_TYPE/list";
export const URL_LOOKUP_CANCELREASON = "/lu05/lookup/CANCEL_REASON_TYPE/list";
export const URL_LOOKUP_GENDER = "/lu05/lookup/GENDER/list";
export const URL_LOOKUP_PROVINCE = "/lu05/lookup/PROVINCE/list";
export const URL_LOOKUP_CITY = "/lu05/lookup/CITY/list";
export const URL_LOOKUP_PAYMENT_TYPE = "/lu05/lookup/PAYMENT_TYPE/list";
export const URL_LOOKUP_PAYMENT_CHANNEL = "/lu05/lookup/PAYMENT_CHANNEL/list";
export const URL_FORGOT_PASSWORD = "/account/ac09/forgotPassword";
export const URL_RESET_PASSWORD = "/account/ac08/updateAccountPassword";

export const URL_ACCOUNT_LOGIN = "/account/ac01/login";
export const URL_ACCOUNT_LOGOUT = "/account/ac03/logout";
export const URL_ACCOUNT_CREATE = "/account/ac04/createacc";
export const URL_ACCOUNT_UPDATE = "/account/ac07/updateacc";
export const URL_ACCOUNT_RETRIEVE = "/account/ac06/viewacc/";
export const URL_ACCOUNT_LIST = "/account/ac05/accountlist/";
export const URL_ACCOUNT_ROLE_LIST = "/roles/ro05/list";
export const URL_ACCOUNT_ACTIVECOMPANY = "/company/cp05/list";
export const URL_ACCOUNT_OTP = "/account/ac01/verifyaccountotp";

export const URL_COMPANY_LIST = "/company/cp02/companylist/";
export const URL_COMPANY_RETRIEVE = "/company/cp03/viewcomp/";
export const URL_COMPANY_UPDATE = "/company/cp04/updatecomp";
export const URL_COMPANY_CREATE = "/company/cp01/createcomp";
export const URL_COMPANY_PRODUCT_LIST = "/company/cp06/listproduct/";
export const URL_COMPANY_LOGO_RETRIVE = "/company/cp09/viewcomplogo/";
export const URL_MEMBER_AGENT_CODE_LIST = "/company/cp05/memberagent/list";

export const URL_ROLE_LIST = "/roles/ro02/rolelist/";
export const URL_ROLE_FUNCTION_LIST = "/roles/ro06/function/list";
export const URL_ROLE_RETRIEVE = "/roles/ro03/viewrole/";
export const URL_ROLE_CREATE = "/roles/ro01/createrole";
export const URL_ROLE_UPDATE = "/roles/ro04/updaterole";

export const URL_TRNX_COUNT = "/dh01/dashboard/count/";
export const URL_CREATE_TRNX_COUNT = "/dh02/dashboard/trnxcount";
export const URL_TRNX_STATISTIC_DET = "/dh04/dashboard/trnxdetail/";
export const URL_CLAIM_APP_COUNT = "/dh03/dashboard/claimappcount";
export const URL_TRNX_CLAIM_APP_DET = "/dh05/dashboard/claimappdetail/";
export const URL_TRNX_ENQUIRY_LIST = "/transaction/tx05/trnxenquirylist/";
export const URL_TRNX_VIEW = "/transaction/tx02/viewtrnx/";
export const URL_TRNX_CANCEL = "/transaction/tx07/canceltrnx/";
export const URL_TRNX_CANCEL_INIT = "/transaction/tx06/initcanceltrnx/";
export const URL_TRNX_CREATE = "/transaction/tx01/createtrnx";
export const URL_TRNX_DOWNLOAD_TEMPLATE = "/transaction/tx09/downloadtemplate/";
export const URL_TRNX_DOWNLOAD_PDF = "/transaction/tx10/downloadPdf";
export const URL_TRNX_BULKCREATION_LIST = "/transaction/tx08/bulkcreationlist/";
export const URL_TRNX_CI_STATUS = "/transaction/tx05/status/list";
export const URL_TRNX_PROVINCE_CITY = "/transaction/tx09/province/";

// export const URL_PREMIUM_PLAN = "/plan/getplan/plan";

export const URL_CLAIM_APPROVAL_LIST = "/claimapp/ca01/approval/claimlist/";
export const URL_CLAIM_APPROVAL_STATUS_LIST = "/claimapp/ca07/approval/status/list";
export const URL_CLAIM_APPROVAL_RETRIEVE = "/claimapp/ca02/approval/viewclaim/";
export const URL_CLAIM_APPROVAL_APPROVE = "/claimapp/ca03/approval/approve";
export const URL_CLAIM_APPROVAL_DECLINED = "/claimapp/ca04/approval/decline";
export const URL_CLAIM_APPROVAL_REVIEWDOC = "/claimapp/ca05/approval/reviewdoc";
export const URL_CLAIM_APPROVAL_NOTIFYPAYMENT = "/claimapp/ca06/approval/notifypayment";
export const URL_CLAIM_APPROVAL_DOWNLOADFILE = "/claimapp/ca08/approval/download/";

export const URL_CLAIM_REGISTRATION = "/claims/cl03/regclaim";
export const URL_INDIVIDUAL_CLAIM_REGISTRATION = "/individual/claims/cl03/individualregclaim";
export const URL_CLAIM_VERIFYCLAIMOTP = "/claims/cl02/verifyclaimotp";
export const URL_INDIVIDUAL_CLAIM_VERIFYCLAIMOTP = "/individual/claims/cl02/individualverifyclaimotp";
export const URL_CLAIM_SUBMIT = "/claims/cl01/submitclaim";
export const URL_CLAIM_REUPLOAD = "/claims/cl04/reupload";
export const URL_CLAIM_REUPLOAD_SUBMIT = "/claims/cl05/reupload/submit";

export const URL_REPORT_COMPANY_PRODUCT_LIST = "/company/cp07/product/list/";
export const URL_REPORT_CERTIFICATE_ISSUED_LIST = "/rp01/report/trnxlist/";
export const URL_REPORT_CLAIM_REGISTERED_LIST = "/rp03/report/claimslist/";
export const URL_REPORT_CERTIFICATE_ISSUED_DWNLD = "/rp02/report/trnxlistdownload";
export const URL_REPORT_CLAIM_REGISTERED_DWNLD = "/rp04/report/claimslistdownload";

export const URL_BATCH_HOSTSUBMISSION = "/bc01/batch/hostsubmission";

export const URL_FILEGATEWAY_HOST = "https://greatAXGateway.greateasterngeneral.com/ax/v1/portal/fg01"
export const URL_FILEGATEWAY_DEFAULT = "/" + constant.defaultCountryCode + "/v1/filegateway/file/";

export const URL_PRODUCT_VERSION = "/claims/cl08/getprodversion";

//export const URL_PRODUCT_INFO = "/transaction/tx04/prodinfo/";

export const URL_UPDATE_PAYMENT = "/payment/py01/updatetrnxpayment";
export const URL_UPDATE_PAYMENT_INIT = "/payment/py02/initupdatepayment/";

export const URL_CREATE_PAYMENT_INT = "/payment/py03/int/createpayment/";
export const URL_GET_PAYMENT_INT = "/payment/py04/int/getpayment/";

export const URL_B2B_PRODUCT_INFO = "/transaction/tx04/prodinfo/";
export const URL_B2B_PREMIUM_PLAN = "/plan/getplan/plan";
export const URL_B2B_TRNX_CREATE = "/transaction/tx01/createtrnx";
export const URL_B2B_TRNX_DOWNLOAD_PRODUCT_INFO = "/transaction/tx11/downloadProductInfo/";

export const URL_B2C_PRODUCT_INFO = "/transaction/tx04/b2c/prodinfo";
export const URL_B2C_PREMIUM_PLAN = "/plan/getplan/b2c/plan";
export const URL_B2C_TRNX_CREATE = "/transaction/tx01/b2c/createtrnx";
export const URL_B2C_TRNX_DOWNLOAD_PDF = "/transaction/tx10/b2c/downloadPdf";
export const URL_B2C_TRNX_DOWNLOAD_PRODUCT_INFO = "/transaction/tx11/b2c/downloadProductInfo/";

export const URL_B2C_PAYMENT_MOCK_SUCCESS = "/rws/mock/payment/success";
export const URL_B2C_PAYMENT_MOCK_FAILED = "/rws/mock/payment/failed";

export const URL_COMMON_POLICY_TYPE_LIST = "/common/cm01/common/policytype/list";
export const URL_COMMON_PROMO_CODE = "/common/cm02/common/getPromoCode";
export const URL_COMMON_DESTINATION_CATEGORY = "/common/cm03/common/destinationCategory/";
export const URL_COMMON_DESTINATION_COUNTRY = "/common/cm04/common/destinationCountry/";