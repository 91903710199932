import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import $ from 'jquery';
import { Get, Post } from '../../../utils/AxFetch';
import { format } from 'date-fns';
import * as urlConstant from '../../../constants/urlConstant';
import * as Constant from './../../../constants/Constant';
import * as Validate from './../../../utils/CommonValidate';
import ProposerInfo from './ProposerInfo';
import InsuredPersons from './InsuredPersons';
import InsuredPersonsAdultChildren from './InsuredPersonsAdultChildren';
import RiskItems from './RiskItems';
import QuestionFields from './QuestionFields';
import { addYears, subDays } from 'date-fns';

const POS = ({ channel, respData, fields, respDropdownList, fieldValue, setFieldValue, getRespPremiumPlan, nextSlideTransitions, setRespData, setAdultInsuredItems, setChildrenInsuredItems}) => {
    const { t } = useTranslation();
    const t2 = (label) => {
        return t('en_translations:'+label); 
    }
    const dispatch = useDispatch();
    const [noOfInsured, setNoOfInsured] = useState(fieldValue["riskItems"]["noOfInsured"] ? fieldValue["riskItems"]["noOfInsured"].value : null);
    const [isEffectiveEndDateReadOnly, setIsEffectiveEndDateReadOnly] = useState(false);
    
    const [isChecked, setIsChecked] = useState(false);
    const [isAdultChecked, setAdultIsChecked] = useState(false);
    
    const dropdownList = (optList) => {
        if (optList) {
            return optList.map(option => {
                return {shortCode: option.shortCode, value: option.value}
            })
        } else return [];
    }

    const handleUnCheckCheckboxPerson = () => {
        setIsChecked(false);
    };

    const handleUncheckCheckbox = () => {
        setAdultIsChecked(false);
    };

    const clearAdultChildrenInsuredItems = () => {
            // reset all the value of adult insured person and children insured person when select trip type dorpdown
            let currentFieldValue = {...fieldValue};
            
            currentFieldValue["adultInsuredItems"].splice(0)
            currentFieldValue["childrenInsuredItems"].splice(0)
    }

    const onChangeInputSelect = (e, item, selectFlag) => {
        if (e.target && item) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    if (selectFlag) {
                        currentFieldValue[item][e.target.name].desc = $('select[name="'+e.target.name+'"] option:selected').text()
                    }
                        currentFieldValue[item][e.target.name].value = e.target.value
                        if("propertyType" === e.target.name) {
                            currentFieldValue[item]["noOfFloors"].value = '0'
                            currentFieldValue[item]["houseType"].value = "LIVING"
                            currentFieldValue[item]["houseType"].desc = "Living House"
                        }
                        setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const onChangeDobInputPopulateAge = (dob, item, name) => {
        let currentFieldValue = {...fieldValue};
        if(dob) {
            let age = ageCalculation(dob)

            if( currentFieldValue[item] && currentFieldValue[item][name]){
                currentFieldValue[item][name].value = age;
            }
        }
    }

    const onChangeCurrency = (e, item) => {
        if (e.target && item) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    let value = e.target.value;
                    if (value.includes(".")) {
                        value = value.replaceAll(".","");     
                    }           
                    currentFieldValue[item][e.target.name].value = value
                    let initVal = 0 ;
                    for(let items in currentFieldValue[item]) {
                        if(items.startsWith("sumInsured")) {
                            initVal += (parseInt(currentFieldValue[item][items].value) || 0);
                        }         
                    }
                    if(currentFieldValue[item]["totalSumInsured"])
                        currentFieldValue[item]["totalSumInsured"].value = initVal.toString();
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const onChangeInputSelectInsuredFields = (e, item, index, selectFlag) => { 
        if (e.target && item) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    let obj = currentFieldValue[item][index];
                    if (selectFlag) {
                        obj[e.target.name].desc = $('#insuredPerson_'+e.target.name+'_'+index+' option:selected').text()
                    }
                    obj[e.target.name].value = e.target.value
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const onChangeDate = (dtValue, item, name) => {
        if (dtValue && item && name) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    currentFieldValue[item][name].value = dtValue
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const ageCalculation = (dob) => {
        var ageDifMs = Date.now() - dob.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const durationDaysCalculate = (effectiveStartDate, effectiveEndDate) => {
        let currentFieldValue = {...fieldValue};
        let effStartDateParts = effectiveStartDate.split("/");
        let effEndDateParts = effectiveEndDate.split("/");
        let effStartDt = new Date(effStartDateParts[2], effStartDateParts[1] - 1, effStartDateParts[0]);
        let effEndDt = new Date(effEndDateParts[2], effEndDateParts[1] - 1, effEndDateParts[0]);

        const diffInMs = Math.abs(effEndDt - effStartDt);
        let durationDays = diffInMs / (1000 * 60 * 60 * 24);
        if (durationDays || durationDays === 0) {
            currentFieldValue["riskItems"]["duration"].value = durationDays
            setFieldValue(currentFieldValue);
        }
    }

    const durationAnnualPopulate = (effectiveStartDate) => {
        let currentFieldValue = { ...fieldValue };
        let effStartDateParts = effectiveStartDate.split("/");
        let effStartDt = new Date(effStartDateParts[2], effStartDateParts[1] - 1, effStartDateParts[0]);
    
        // Add one year to the start date
        let effEndDt = subDays(addYears(effStartDt, 1), respData.dayType && respData.dayType === "MIDNIGHT" ? 1 : 0) 

        // Calculate duration in milliseconds
        let durationMs = effEndDt.getTime() - effStartDt.getTime();
    
        // Convert milliseconds to days
        let durationDays = durationMs / (1000 * 60 * 60 * 24);
    
        // Update currentFieldValue with durationDays
        currentFieldValue["riskItems"]["duration"].value = durationDays;
        currentFieldValue["riskItems"]["effectiveEndDate"].value = effEndDt;

        setFieldValue(currentFieldValue);
        setIsEffectiveEndDateReadOnly(true);
    }   

    const onChangeDurationSelect = (e, name, value) => {
        if (e && name) {
            let currentFieldValue = { ...fieldValue };
            for (var key in currentFieldValue) {
                if (key === "riskItems") {
                    if (name === "durationType") {
                        // Validate duration type Annual and Daily
                        let effectiveStartDate = currentFieldValue["riskItems"]["effectiveStartDate"].value
                        if (effectiveStartDate) {
                            validateDurationType(value, format(effectiveStartDate, Constant.dateFormat_B));
                        }
                    }
                }
            }
        }
    }

    const validateDurationType = (durationType, effectiveStartDate) => {
        if (durationType === "ANNUALLY") {
                return durationAnnualPopulate(effectiveStartDate);
        } else {
            setIsEffectiveEndDateReadOnly(false)
        }
    }

    const validateEffectiveDate = (dtValue, name) => {
        if (dtValue && name) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === "riskItems") {
                    let effectiveStartDate = '';
                    let effectiveEndDate = '';
                    
                    if (name === "effectiveStartDate" && currentFieldValue["riskItems"]["effectiveEndDate"].value) {
                        effectiveStartDate = format(dtValue, Constant.dateFormat_B);
                        effectiveEndDate = format(fieldValue["riskItems"]["effectiveEndDate"].value, Constant.dateFormat_B);
                        
                    }
                    if (name === "effectiveEndDate" && currentFieldValue["riskItems"]["effectiveStartDate"].value) {
                        effectiveStartDate = format(fieldValue["riskItems"]["effectiveStartDate"].value, Constant.dateFormat_B);
                        effectiveEndDate = format(dtValue, Constant.dateFormat_B);
                    }
                    // Validate duration type Annual and Daily
                    if(name === "effectiveStartDate" && currentFieldValue["riskItems"]["durationType"]){
                        let durationType = currentFieldValue["riskItems"]["durationType"].value
                        return validateDurationType(durationType, format(dtValue, Constant.dateFormat_B)); 
                    }

                    if (effectiveStartDate && effectiveEndDate) {
                        if (!(Validate.validateDate(effectiveStartDate, effectiveEndDate))) {
                            return ($('#errMsg_effectiveStartDate').text(t('error.message.greaterthan.enddate')))
                        } else if ($('#errMsg_effectiveStartDate').text()) {
                            $('#errMsg_effectiveStartDate').text('');
                        }
                        return durationDaysCalculate(effectiveStartDate, effectiveEndDate);
                    }
                }
            }
        }
    }

    const onChangeDateInsuredFields = (dtValue, item, name, index) => {
        if (dtValue && item && name) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    let obj = currentFieldValue[item][index];
                    obj[name].value = dtValue;
                    const newDOB = dtValue;
                    

                    if(obj['age']) {
                        const newAge = calculateAge(newDOB);
                        obj['age'].value = newAge;
                    }
                    
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const onChangeList = (e, item, idx) => {
        if (e.target && item) {
            let currentFieldValue = {...fieldValue};
            for (var key in currentFieldValue) {
                if (key === item) {
                    currentFieldValue[item][e.target.name].value[idx] = e.target.value
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const handleAddList = (item, name) => {
        if (item && name) {
            let currentFieldValue = {...fieldValue};
            
            for (var key in currentFieldValue) {
                if (key === item) {
                    let currentList = currentFieldValue[item][name].value;
                    currentList[currentList.length] = ""
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }

    const handleRemoveList = (item, name, idx) => {
        if (item && name) {
            let currentFieldValue = {...fieldValue};
            
            for (var key in currentFieldValue) {
                if (key === item) {
                    let currentList = currentFieldValue[item][name].value;
                    currentList.splice(idx, 1);
                    setFieldValue(currentFieldValue);
                }
            }
        }
    }
    
    const calculateAge = (dob) => {
        const dobYear = new Date(dob).getFullYear();
        const currentYear = new Date().getFullYear();
        return currentYear - dobYear;

    };

    const handleCheckboxChange = (e, type) => {
        const isChecked = e.target.checked;
        if (type === 'adultInsuredItems') {
            setAdultIsChecked(isChecked);
        } else {
            setIsChecked(isChecked);
        }
        if (isChecked) {
            const proposerItems = fieldValue.proposerItems;
            if (proposerItems && fieldValue[type] && fieldValue[type].length > 0) {
                const updatedInsuredPersons = fieldValue[type].map((type, index) => {
                    if (index === 0) {
                        const updatedPerson = { ...type };
                        for (const field in proposerItems) {
                            if (proposerItems.hasOwnProperty(field) && type.hasOwnProperty(field)) {
                                updatedPerson[field] = { value: proposerItems[field].value };
                            }
                        }
                        if (type.age) {
                            const ageValue = calculateAge(proposerItems.dob.value);
                            updatedPerson.age = { value: ageValue };
                        }
                        return updatedPerson;
                    } else {
                        return type;
                    }
                });
                setFieldValue({ ...fieldValue, [type]: updatedInsuredPersons });
            }
        }
    };

    const validateForm = () => {
        $('[id^="errMsg_"]').empty();

        let errorProposerFields, errorInfoFields, errorInsuredFields, errorAdultInsuredFields, errorChildrenInsuredFields, errorRiskFields, errorDynamicProposerFields,errorQuestionFields = false;
        let currentFieldValue = {...fieldValue};

        for (let key in fieldValue["proposerItems"]) {
            if (!fieldValue["proposerItems"][key].value && $("#label_"+key).hasClass( "required" )) {
                $('#errMsg_proposer_'+key).text(t('error.message.input.blank', { 0: t("trnx.proposer."+key) }));
                errorProposerFields = true;
            }else{
                if (fieldValue["proposerItems"]["email"].value&&!Validate.validateEmail(fieldValue["proposerItems"]["email"].value)) {
                    $('#errMsg_proposer_email').text(t('error.message.invalid.field', { 0: t("trnx.proposer.email") }));
                    errorProposerFields = true;
                }
                if(fieldValue["proposerItems"]["name"].value&&!Validate.validateAlphaApostrophe(fieldValue["proposerItems"]["name"].value)){
                    $('#errMsg_proposer_name').text(t('error.message.invalid.field', { 0: t("trnx.proposer.name") }));
                    errorProposerFields = true;
                }
                if(fieldValue["proposerItems"]["mobileNo"].value&&!Validate.validateMobileNoLength(fieldValue["proposerItems"]["mobileNo"].value)){
                    $('#errMsg_proposer_mobileNo').text(t('error.message.between.length', { 0: t("trnx.proposer.mobileNo") }));
                    errorProposerFields = true;
                }
            }
        }

        if (respData.infoFields.length) {
            respData.infoFields.forEach(infoField => {
                if (infoField.isRequired) {
                    let value = fieldValue["infoItems"][infoField.key].value;
                    for (let i = 0; i < value.length; i++) {
                        if (!value[i].trim()) {
                            $('#errMsg_info_'+infoField.key).text(t('error.message.input.blank', { 0: infoField.axLabel && infoField.axLabel.axLabelId 
                                ? infoField.axLabel.axLabelId : t("trnx.info."+infoField.key) 
                                ? t("trnx.info."+infoField.key) : infoField.key }));
                            errorInfoFields = true;
                            break;
                        }
                    }
                }
            });
        }

        if (respData.insuredFields.length) {
            respData.insuredFields.map((insuredItem) => {
                return fieldValue["insuredItems"].map((insuredItemValue, i) => {
                    if (!insuredItemValue[insuredItem.key].value) {
                        $('#errMsg_insuredPerson_'+insuredItem.key+'_'+i).text(t('error.message.input.blank', { 0: t("trnx.insured."+insuredItem.key) }));
                        return errorInsuredFields = true;
                    }else if (insuredItemValue["email"]) {
                        if (insuredItemValue["email"].value) {
                            if (!Validate.validateEmail(insuredItemValue["email"].value)) {
                                $('#errMsg_insuredPerson_email').text(t('error.message.invalid.field', { 0: t("trnx.insured.email") }));
                                return errorInsuredFields = true;
                            } else return true;
                        } else return true;
                    }else if(insuredItemValue["name"]){
                        if(insuredItemValue["name"].value && !Validate.validateAlphaApostrophe(insuredItemValue["name"].value)){
                            $('#errMsg_insuredPerson_name_'+i).text(t('error.message.invalid.field', { 0: t("trnx.insured.name") }));
                            return errorInsuredFields = true;
                        }
                    }
                    else return true;
                })
            })
        }

        if (fieldValue["riskItems"]['tripType'] && fieldValue["riskItems"]['tripType'].value) {
            respData.tripTypeFields.map(tripType => {
                if (fieldValue["riskItems"]['tripType'].value === tripType.key) {
                    if (respData.insuredFields.length) {
                        respData.insuredFields.map((insuredItem) => {
                            for (var i = 0; i < tripType.noOfAdult.minNo; i++) {
                                // Skip validation for childDob in adultInsuredItems
                                if (!currentFieldValue["adultInsuredItems"][i][insuredItem.key].value && insuredItem.key !== 'childDob') {
                                    $('#errMsg_adultInsuredItems_' + insuredItem.key + '_' + i).text(t('error.message.input.blank', { 0: t("trnx.insured." + insuredItem.key) }));
                                    errorAdultInsuredFields = true;
                                } else if (currentFieldValue["adultInsuredItems"][i]["email"]) {
                                    if (currentFieldValue["adultInsuredItems"][i]["email"].value) {
                                        if (!Validate.validateEmail(currentFieldValue["adultInsuredItems"][i]["email"].value)) {
                                            $('#errMsg_adultInsuredItems_email').text(t('error.message.invalid.field', { 0: t("trnx.insured.email") }));
                                            errorAdultInsuredFields = true;
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }

        if (fieldValue["riskItems"]['tripType'] && fieldValue["riskItems"]['tripType'].value) {
            respData.tripTypeFields.map(tripType => {
                if (fieldValue["riskItems"]['tripType'].value === tripType.key) {
                    if (respData.insuredFields.length) {
                        respData.insuredFields.map((insuredItem) => {
                            for (var i = 0; i < tripType.noOfChildren.minNo; i++) {  
                                // Skip validation for dob in childrenInsuredItems
                                if (!currentFieldValue["childrenInsuredItems"][i][insuredItem.key].value && insuredItem.key !== 'dob') {
                                    $('#errMsg_childrenInsuredItems_' + insuredItem.key + '_' + i).text(t('error.message.input.blank', { 0: t("trnx.insured." + insuredItem.key) }));
                                    errorChildrenInsuredFields = true;
                                } else if (currentFieldValue["childrenInsuredItems"][i]["email"]) {
                                    if (currentFieldValue["childrenInsuredItems"][i]["email"].value) {
                                        if (!Validate.validateEmail(currentFieldValue["childrenInsuredItems"][i]["email"].value)) {
                                            $('#errMsg_childrenInsuredItems_email').text(t('error.message.invalid.field', { 0: t("trnx.insured.email") }));
                                            errorChildrenInsuredFields = true;
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }

        if (respData.riskFields.length) {
            respData.riskFields.forEach((riskField) => {
                const riskItem = fieldValue["riskItems"];
                if (riskItem[riskField.key] !== undefined) {
                    if (riskField.isRequired && !riskItem[riskField.key].value) {
                        errorRiskFields = true;
                        return ($('#errMsg_'+riskField.key).text(t('error.message.input.blank', { 0: riskField.axLabel && riskField.axLabel.axLabelId 
                            ? riskField.axLabel.axLabelId : t('id_riskitems:'+riskField.key) 
                            ? t('id_riskitems:'+riskField.key) : riskField.key})))
                        } else{
                            if(riskItem["jobDesc"] && riskItem["jobDesc"].value&&!Validate.validateAlphaApostrophe(riskItem["jobDesc"].value)){
                                $('#errMsg_jobDesc').text(t('error.message.invalid.field', { 0: t("jobDesc") }));
                                errorRiskFields = true;
                            } 
                            if(riskItem["beneficiaryName"]&&riskItem["beneficiaryName"].value&&!Validate.validateAlphaApostrophe(riskItem["beneficiaryName"].value)){
                                $('#errMsg_beneficiaryName').text(t('error.message.invalid.field', { 0: t("beneficiaryName") }));
                                errorRiskFields = true;
                            } 
                            if ($('#errMsg_effectiveStartDate').text()) {
                                errorRiskFields = true;
                            }
                        }
                }
            })
        }

        if (respData.proposerFields.length) {
            respData.proposerFields.forEach((proposerField) => {
                if (fieldValue["dynamicProposerFields"][proposerField.key] !== undefined) {
                    if (proposerField.isRequired && !fieldValue["dynamicProposerFields"][proposerField.key].value) {
                        errorDynamicProposerFields = true;
                        return ($('#errMsg_'+proposerField.key).text(t('error.message.input.blank', { 0: proposerField.axLabel && proposerField.axLabel.axLabelId 
                            ? proposerField.axLabel.axLabelId : t('id_riskitems:' + proposerField.key) 
                            ? t('id_riskitems:' + proposerField.key) : proposerField.key} )))
                    }
                }
            })
        }

        if (respData.questionFields.length) {
            respData.questionFields.forEach(questionField  => {
                if(fieldValue["questionItems"][questionField.key].value == "") {
                    errorQuestionFields = true;
                    return ($('#errMsg_'+questionField.key).text(t('error.message.input.blank', {0: questionField.axLabel.axLabelId})))
                }
            })
        }

        if (errorProposerFields || errorInfoFields || errorInsuredFields || errorRiskFields || errorDynamicProposerFields || errorQuestionFields || errorAdultInsuredFields || errorChildrenInsuredFields) {
            window.scrollTo(0, 0);
            return false;
        }
        else return true;
    }

    const getPremium = async() => {
        if (validateForm()) {
            let riskItems, infoItems, proposerFields, questionItems = {}
            let insuredItems = [];
            let adultInsuredItem =[];
            let childrenInsuredItem =[];

            if (respData.infoFields.length) {
                    let info ={};
                    respData.infoFields.forEach(infoField => {
                        if (fieldValue["infoItems"][infoField.key]) {
                            info = Object.assign( { [infoField.key]: fieldValue["infoItems"][infoField.key].value }, info );
                        }
                    });
                    infoItems = info;
            }

            if (respData.riskFields.length) {
                let risk = {}
                respData.riskFields.map((riskField) => {
                    if (fieldValue["riskItems"][riskField.key]) {
                        if (fieldValue["riskItems"][riskField.key].value instanceof Date) {
                            let date = format(fieldValue["riskItems"][riskField.key].value, Constant.dateFormat_B);
                            return risk = Object.assign( { [riskField.key]: date }, risk );
                        } else return risk = Object.assign( { [riskField.key]: fieldValue["riskItems"][riskField.key].value }, risk );
                    } else return true;
                })
                riskItems = risk;
            }

            if (respData.insuredFields.length) {
                let insuredArray =[];
                fieldValue["insuredItems"].forEach((insuredItem) => {
                    let insured ={};
                    for (let key in insuredItem) {
                        if (insuredItem[key].value instanceof Date) {
                            let date = format(insuredItem[key].value, Constant.dateFormat_B);
                            insured = Object.assign( { [key]: date }, insured );
                        } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                    }
                    insuredArray.push(insured);
                });
                insuredItems = insuredArray
            }

            if (respData.insuredFields.length) {
                let insuredArray =[];
                fieldValue["adultInsuredItems"].forEach((insuredItem) => {
                    let insured ={};
                    let allFieldsEmpty = true

                    // Do check if all the input is empty, do not push into array
                    for (let key in insuredItem) {
                        if(insuredItem[key].value) {
                            allFieldsEmpty = false
                        }
                    }

                    if(!allFieldsEmpty) {
                        for (let key in insuredItem) {
                            if (insuredItem[key].value instanceof Date) {
                                let date = format(insuredItem[key].value, Constant.dateFormat_B);
                                insured = Object.assign( { [key]: date }, insured );
                            } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                        }
                        insuredArray.push(insured);
                    } 
                });
                adultInsuredItem = insuredArray;
                setAdultInsuredItems(insuredArray);
            }

            if (respData.insuredFields.length) {
                let insuredArray =[];
                fieldValue["childrenInsuredItems"].forEach((insuredItem) => {
                    let insured ={};
                    let allFieldsEmpty = true

                    // Do check if all the input is empty, do not push into array
                    for (let key in insuredItem) {
                        if(insuredItem[key].value) allFieldsEmpty = false
                    }

                    if(!allFieldsEmpty) {
                        for (let key in insuredItem) {
                            if (insuredItem[key].value instanceof Date) {
                                let date = format(insuredItem[key].value, Constant.dateFormat_B);
                                insured = Object.assign( { [key]: date }, insured );
                            } else insured = Object.assign( { [key]: insuredItem[key].value }, insured );
                        }
                        insuredArray.push(insured);
                    }
                });
                childrenInsuredItem = insuredArray;
                setChildrenInsuredItems(insuredArray)
            }

            if (respData.proposerFields.length) {
                let proposer = {}
                respData.proposerFields.map((proposerField) => {
                    if (fieldValue["dynamicProposerFields"][proposerField.key]) {
                        return proposer = Object.assign( { [proposerField.key]: fieldValue["dynamicProposerFields"][proposerField.key].value }, proposer );
                    } else return true;
                })
                proposerFields = proposer;
            }

            if (respData.questionFields.length) {
                let questions = {}
                respData.questionFields.map((questionField) => {
                    if (fieldValue["questionItems"][questionField.key]) {
                       return questions = Object.assign( { [questionField.key]: fieldValue["questionItems"][questionField.key].value }, questions );
                    } else return true;
                })
                questionItems = questions;
            }

            let params = {
                productCode : respData.code,
                riskItems : riskItems,
                infoItems : infoItems,
                insuredPersons : insuredItems,
                proposerFields : proposerFields,
                questionItems: questionItems,
                adultInsuredPersons : adultInsuredItem,
                childrenInsuredPersons : childrenInsuredItem,
            }

            let url = '';        
            if (channel === Constant.LOGIN_CHANNEL.PARTNER)
                url = urlConstant.URL_B2B_PREMIUM_PLAN;
            else if (channel ===Constant.LOGIN_CHANNEL.CONSUMER)
                url = urlConstant.URL_B2C_PREMIUM_PLAN;

            let resp = await Post(urlConstant.URL_BASED + url, params, dispatch);

            if (!resp.isError && resp.respData.data) {
                getRespPremiumPlan(resp.respData.data, params, true);
                nextSlideTransitions(true);
                window.scrollTo(0, 0);
            }
        }
    }

    if (fields) return (
        <div>
        <Row className="mt-3"><Col className="titleH4"><h4>{t("proposal")}</h4></Col></Row>
        <Row><Col className="titleH4"><h6><i>{t2("proposal")}</i></h6></Col></Row>
        <Row><Col className="borderBottom"></Col></Row>
        <ProposerInfo 
            respData={respData}
            fieldValue={fieldValue} 
            setFieldValue={setFieldValue} 
            setRespData={setRespData}
            onChangeInputSelect={onChangeInputSelect} 
            onChangeDate={onChangeDate}
            fields={fields}
            onChangeCurrency={onChangeCurrency}
            onChangeDobInputPopulateAge={onChangeDobInputPopulateAge}
        />
        <RiskItems 
            respData={respData} 
            fields={fields} 
            fieldValue={fieldValue} 
            setNoOfInsured={(val)=>{setNoOfInsured(val)}} 
            onChangeInputSelect={onChangeInputSelect}
            onChangeCurrency={onChangeCurrency}
            onChangeDate={onChangeDate} 
            validateEffectiveDate={validateEffectiveDate}
            dropdownList={dropdownList}
            onChangeList={onChangeList} 
            handleAddList={handleAddList} 
            handleRemoveList={handleRemoveList}
            onChangeDurationSelect={onChangeDurationSelect}
            setFieldValue={setFieldValue}
            setRespData={setRespData}
            isEffectiveEndDateReadOnly={isEffectiveEndDateReadOnly}
            clearAdultChildrenInsuredItems={clearAdultChildrenInsuredItems}
            handleUncheckCheckbox={handleUncheckCheckbox}    
        />
        {
            respData.tripTypeFields && respData.tripTypeFields.map(tripType => {
                if(fieldValue["riskItems"]["tripType"] 
                && fieldValue["riskItems"]["tripType"].value === tripType.key) {
                    return (
                        <div key={tripType.key}>
                        <InsuredPersonsAdultChildren 
                            respData={respData}
                            fieldValue={fieldValue} 
                            setFieldValue={setFieldValue} 
                            noOfInsured={tripType.noOfAdult.maxNo} 
                            minNoOfInsured={tripType.noOfAdult.minNo}
                            onChangeInputSelectInsuredFields={onChangeInputSelectInsuredFields}
                            onChangeDateInsuredFields={onChangeDateInsuredFields}
                            calculateAge={calculateAge}
                            handleCheckboxChange={handleCheckboxChange}
                            isAdultChecked={isAdultChecked}         
                            type={'adultInsuredItems'}
                            label={'adult'}
                        />
                        <InsuredPersonsAdultChildren 
                            respData={respData}
                            fieldValue={fieldValue} 
                            setFieldValue={setFieldValue} 
                            noOfInsured={tripType.noOfChildren.maxNo} 
                            minNoOfInsured={tripType.noOfChildren.minNo}
                            onChangeInputSelectInsuredFields={onChangeInputSelectInsuredFields}
                            onChangeDateInsuredFields={onChangeDateInsuredFields}
                            calculateAge={calculateAge}
                            type={'childrenInsuredItems'}
                            label={'child'}
                        />
                    </div>
                    )
                } else {
                    return null
                }
            })
        }
        <InsuredPersons 
            respData={respData}
            fieldValue={fieldValue} 
            setFieldValue={setFieldValue} 
            noOfInsured={noOfInsured} 
            onChangeInputSelectInsuredFields={onChangeInputSelectInsuredFields}
            onChangeDateInsuredFields={onChangeDateInsuredFields}
            calculateAge={calculateAge}
            handleCheckboxChange={handleCheckboxChange}
            handleUnCheckCheckboxPerson={handleUnCheckCheckboxPerson}
            isChecked={isChecked}
        />
        <QuestionFields
            respData ={respData}
            fields={fields}
            fieldValue={fieldValue}
            dropdownList={dropdownList}
            onChangeInputSelect={onChangeInputSelect}
        />
        <Row className="mt-3">
            <Col>
                <center>
                    <Button onClick={() => {nextSlideTransitions(false);window.scrollTo(0, 0);}}>{t('button.previous')}</Button><> </>
                    <Button onClick={() => getPremium()}>{t('button.next')}</Button>
                </center>
            </Col>
        </Row>
        </div>
    )
    else return "";
};

export default POS;